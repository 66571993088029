import { useItemActionWithConfirmation } from "../api/useAction"
import { downloadFile } from "../api/core"

interface DownloadParams {
  filename?: string;
  apiPath?: string;
}

export const useDownloadFile = (apiPath: string, filename?: string) => {
  const download = useItemActionWithConfirmation<DownloadParams, void>(
    params => downloadFile(params.apiPath || apiPath, params?.filename || filename),
    { skipConfirmation: true }
  )
  return {
    isLoading: download.isRunning,
    download: (params?: DownloadParams) => !download.isRunning && download.run(params || {}),
  };
}

export type DownloadFileData = ReturnType<typeof useDownloadFile>;
