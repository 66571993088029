import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { Logo } from './components/Common';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { UserSettingsProvider } from './toolympus/components/Settings/UserSettingsContext';
import { ThemeSettings } from './theme';
import { MediaLibProvider } from './toolympus/components/medialib';

const userCanUpdateGlobalSettings = (u: DefaultUser | null | undefined) => u?.roles?.includes("admin") || false;


const DrawerPaperS = styled(DrawerPaperView)`
  & .MuiDrawer-paper {
    color: ${ThemeSettings.colors.background.normal};
    background-color: ${ThemeSettings.colors.background.dark};
  }
  & .MuiListItem-root {
    border-left: 4px solid transparent;
    
    &.Mui-selected {
      border-left: 4px solid ${ThemeSettings.colors.primary};
    }
  }
`;

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Выход' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <DictionariesProvider apiPath="/api/settings/dictionaries">
            <UserSettingsProvider apiPath="/api/admin-settings" canUpdateGlobal={userCanUpdateGlobalSettings}>
              <MediaLibProvider
                apiPath="/api/media"
                spaceId="cms"
                getFilepath={mf => `/media/${mf.space_id}/${mf.filename}`}>
                        <AppWrapper>
                            <Sidebar
                                top={<Logo />}
                                menu={<Menu menuItems={menuItems} />}
                                bottom={bottom}
                                open={true}
                                onClose={() => { }}
                                drawerPaperProps={{ shadow: true }}
                                drawerPaperComponent={DrawerPaperS}
                            />
                            <AppContentSimple fit100Height>
                                <Routes routes={routes} notFoundRedirect="/home" />
                            </AppContentSimple>
                        </AppWrapper>
              </MediaLibProvider>
            </UserSettingsProvider>
          </DictionariesProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

