import React, { useMemo } from 'react';
import { useApplicationsList } from './useApplicationsList';
import { Buttons, Form, OccupyFreeSpace, PseudoLink2, SearchField, useDialogState } from '../../toolympus/components/primitives';
import { useOpenByIdFromUrl } from '../../toolympus/hooks/useOpenByIdFromUrl';
import { ApplicationRecord } from './typings';
import { ActionTriggerEditButton } from '../../toolympus/components/Actions';
import { Button } from '@mui/material';
import { MaybeFieldDefinition, TableForFields } from '../../toolympus/components/schemed';
import { ApplicationsConfigurationDialog } from './ApplicationsConfigurationDialog';
import { ApplicationReviewDialog } from './ApplicationReviewDialog';
import { useDownloadFile } from '../../toolympus/hooks/useDownloadFile';
import { GetMultifileDisplay } from './ApplicationsFiles';

interface Props {
  
}




export const ApplicationsList = (props: Props) => {
  const data = useApplicationsList();
  const configDialog = useDialogState();

  const [fields, fileFields] = useMemo(() => {
    const fields: MaybeFieldDefinition[] = [
      ["email"],
      ["display_name"],
      ...data.config.data.fields.map(f => [f._id] as MaybeFieldDefinition),
      ["created_at", { utcToLocal: true }],
    ];

    const fileFields = data.config.data.fields.filter(f => f.fieldtype === "file_multi").map(f => f._id);

    return [fields as MaybeFieldDefinition[], fileFields];
  }, [data.config.data]);

  const downloadFile = useDownloadFile("");

  useOpenByIdFromUrl<ApplicationRecord>("id", "_id", data.data, x => data.review.startEditing(x), data.review.item?._id);
  
  return (<>
    <Form
      title="Заявки"
      headerItems={<>

        <SearchField
          filter={data.filter.filter}
          setFilter={data.filter.setFilter}
          noButton
          />
        
        <OccupyFreeSpace />

        
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers={[
            "plschdka.applications.applications/received",
          ]}
          />
        <Button size="small" color="primary" onClick={() => configDialog.open()}>конфигурация</Button>
      </>}>
      
      <TableForFields
        data={data.data}
        schema={data.schema}
        fields={fields}
        fieldElement={f => {
          if(fileFields.includes(f)) {
            return r => (r as any)[f]
              ? <Buttons>
                  <GetMultifileDisplay
                    fileIds={(r as any)[f] as string || ""}
                    apiPathPrefix="/api/applications/document"
                    downloader={downloadFile}
                    />
                </Buttons>
              : <></>;
          }
          switch(f) {
            case "email":
              return (r,s,orig) => <PseudoLink2 border onClick={() => data.review.startEditing(r)}>{orig}</PseudoLink2>
          }
        }}
        tailAnchor={data.limitTrigger.pagingAnchor}
        />
    </Form>

    <ApplicationsConfigurationDialog
      data={data.config}
      title="Конфигурация"
      {...configDialog}
      />

    <ApplicationReviewDialog
      data={data.review}
      schema={data.schema}
      profileConfiguration={data.config.data}
      remove={data.remove.run}
      />
  </>);
}
