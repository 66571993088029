import { useMemo } from "react";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { fieldtypeToSchemaType } from "../../toolympus/components/ConfigurableForms/useConfigurableFormSubmissions";
import { cropText } from "../../toolympus/components/primitives";
import { Schema } from "../../toolympus/hooks/useSchema";
import { ApplicationConfig } from "./typings";
import { MaybeFieldDefinition } from "../../toolympus/components/schemed";

export const useApplicationsConfiguration = () => {
  const data = useCrudItem<ApplicationConfig>("/api/applications/config", {
    defaultValue: {
      code: "",
      fields: [],
      display_name_format: "",

    },
  });

  return {
    ...data,
  }
}

export const schemaWithConfigurableFields = (baseSchema: Schema, fields: ApplicationConfig["fields"], cfg?: { cropTitles?: boolean }) => {
  return fields.reduce<Schema>(
    (r,f) => {
        r[f._id] = {
            type: fieldtypeToSchemaType(f.fieldtype),
            label: cfg?.cropTitles ? cropText(f.title, 50) : f.title,
        }
        return r;
    },
    { ...baseSchema }
  );
}

export type ApplicationsConfigurationData = ReturnType<typeof useApplicationsConfiguration>;

export const useApplicationControls = (config: ApplicationConfig) => {
  const [schema, fields, fileFields, multifileFields] = useMemo(() => {
    const schema = schemaWithConfigurableFields({}, config.fields || []);

    const fields: MaybeFieldDefinition[] = config.fields.map(f => [f._id]);
    const fileFields = config.fields.filter(f => f.fieldtype === "file").map(f => f._id);
    const multifileFields = config.fields.filter(f => f.fieldtype === "file_multi").map(f => f._id);

    return [
      schema,
      fields,
      fileFields,
      multifileFields,
    ]
  }, [config]);

  return {
    schema,
    fields,
    fileFields,
    multifileFields,
  }
}