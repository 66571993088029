import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useOpenByIdFromUrl = <T,>(searchParam: string, idField: keyof T, list: T[], open: (item: T) => void, activeId: string | undefined) => {
  const [idRequested,setIdRequested] = useState<string | null>(null);
  const history = useHistory();
  
  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get(searchParam);
    if(id) {
      setIdRequested(id);
    }
  }, [searchParam]);

  useEffect(() => {
    const currentSearch = new URLSearchParams(history.location.search);
    if(activeId) {
      currentSearch.set(searchParam, activeId);
    } else {
      currentSearch.delete(searchParam);
    }
    history.replace({ ...history.location, search: currentSearch.toString() });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    if(idRequested && list.length) {
      const found = list.find(x => (x[idField] as any || "").toString() === idRequested);
      if(found) {
        setIdRequested(null);
        open(found);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, idRequested]);
} 
