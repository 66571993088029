import React, { ReactNode } from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CustomFieldType, FormFieldsEditor } from '../../toolympus/components/ConfigurableForms/FormFieldsEditor';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Dialog, DialogState, FormGrid } from '../../toolympus/components/primitives';
import { ApplicationsConfigurationData } from './useApplicationsConfiguration';
import { useEditFormFieldsBasic } from '../../toolympus/components/ConfigurableForms/useEditConfigurableForm';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props extends Omit<DialogState, "open"> {
  data: ApplicationsConfigurationData;
  title: ReactNode;
  hint?: ReactNode;
}

const ApplicationConfigSchema: Schema = {
  display_name_format: { label: "Формат отображаемого имени" },
}


const CustomFieldTypes: CustomFieldType[] = [
  // { value: TopicsFieldType, },
]

export const ApplicationsConfigurationDialog = (props: Props) => {
  const fieldsData = useEditFormFieldsBasic(props.data);
  return (
    <Dialog
      dialogTitle={props.title}
      closeButtonTop
      isOpen={props.isOpen}
      close={() => { props.close(); props.data.reload(); }}
      maxWidth="md"
      actions={
        <Button color="primary" variant="contained" onClick={() => { props.data.save().then(() => props.close()); }}>
          <FormattedMessage id="common.save" />
        </Button>
      }>
      <FormGrid columns="1fr">
        {props.hint}

        <FormControlsForFields
          fields={[
            ["display_name_format"],
          ]}
          schema={ApplicationConfigSchema}
          data={props.data.data}
          onChange={(o,c) => props.data.update(c)}
          />

        <div />

        <Divider />

        <Typography>Поля</Typography>

        <FormFieldsEditor
          data={fieldsData}
          customFieldTypes={CustomFieldTypes}
          noRegexes
          allowSubtypes
          />

      </FormGrid>
    </Dialog>
  );
}
