import React from 'react';
import { PowerEditorBase, PowerEditorBaseProps } from '../../toolympus/components/PowerDoc';
import { useMediaFilesPlugin } from '../../toolympus/components/PowerDoc/plugins/MediaFiles';
import { createWrappedPowerEditor } from '../../toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { ColumnsPlugin } from '../../toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from '../../toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from '../../toolympus/components/PowerDoc/plugins/Expander';
import { PanelPlugin } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from '../../toolympus/components/PowerDoc/plugins/Script';
import { TablesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from '../../toolympus/components/PowerDoc/plugins/Tiles';
import { useInlineImagesPlugin } from '../../toolympus/components/PowerDoc/plugins/InlineImages';

export const PlCmsEditorUnwrapped = (props: PowerEditorBaseProps) => {
  const mediaFilesPlugin = useMediaFilesPlugin();
  const inlineImagesPlugin = useInlineImagesPlugin();

  return (
      <PowerEditorBase
          {...props}
          plugins={[
              mediaFilesPlugin,
              inlineImagesPlugin,
              CustomFieldsBlockPlugin,
              TilesPlugin,
              ColumnsPlugin,
              PanelPlugin,
              ScriptPlugin,
              ExpanderPlugin,
              TablesPlugin,
              
          ]}
          />
  );
}

export const PlCmsEditor = createWrappedPowerEditor({
  EditorComponent: PlCmsEditorUnwrapped
});

