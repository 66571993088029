import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const ThemeSettings = {
  colors: {
    primary: "#c43337",
    secondary: "#193354",
    text: "#030505",

    primaryShade: {
    },
    secondaryShade: {
    },
    
    background: {
      normal: "#ffffff",
      dark: "#030505",
    },

    shadow: {
      normal: "#22222280",
    },
  },

  geometry: {
    border: {
      radius: {
        panel: 24,
        controls: 12,
      },
    },

    shadow: {
      panel: "2px 2px 10px -2px",
      panel_light: "1px 1.5px 18px -4px",
      panel_glow: "0px -2px 25px -5px",
    },
  },
}

export const theme = createTheme({
    palette: {
        primary: {
            main: ThemeSettings.colors.primary,
        },
        secondary: {
            main: ThemeSettings.colors.secondary,
        },
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
