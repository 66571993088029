import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Buttons, FormGrid, SimpleDialog, Tooltip, useCopyText } from '../primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldType, Schema, createSelectSchema, mergeSchema } from '../../hooks/useSchema';
import { FormControlsForFields } from '../schemed';
import { Add, ArrowDownward, ArrowUpward, DeleteOutlined, FileCopyOutlined, Fingerprint, InfoOutlined, PhoneOutlined } from '@mui/icons-material';
import isHotkey from 'is-hotkey';
import { FormFieldsData } from './useEditConfigurableForm';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: 1rem;
    & > :last-child {
        align-self: center;
    }
`;

const FieldWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 0.5rem;

    padding: 0.5rem 0 0.5rem 1rem;
    background: #eeeeee80;
    border-radius: 8px;
    box-shadow: 2px 2px 5px -3px #22222280;

    & > :first-child {
        align-items: end;
    }
    & > :last-child {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;
    }
`;


const FieldTypes = ["text", "text_multiline", "date", "select_radio", "select_multi", "email", "boolean", "number", "file", "file_multi", "rank"];

const FieldSchema: Schema = {
    title: { label_id: "forms.field.title" },
    fieldtype: {
        label_id: "forms.field.fieldtype",
        type: FieldType.select,
        values: FieldTypes.map(ft => ({ value: ft, label: ft })),
    },
    is_required: {
        label_id: "forms.field.is_required",
        type: FieldType.bool,
    },
    is_hidden: {
        label_id: "forms.field.is_hidden",
        type: FieldType.bool,
    },
    hint: {
        label_id: "forms.field.hint",
        type: FieldType.textlong,
    },
    validation_regex: {
      label_id: "forms.field.validation_regex",
    },
    subtype: {
      label_id: "forms.field.subtype",
    },
    options_text: {
        label_id: "forms.field.options",
        type: FieldType.textlong,
    },
    block_submit_if: {
        label_id: "forms.field.block_submit_if"
    },
    hide_next_if: {
        label_id: "forms.field.hide_next_if"
    },
    block_submit_message: {
        label_id: "forms.field.block_submit_message",
        type: FieldType.textlong,
    },
}

export interface CustomFieldType {
  value: string;
  canHaveRegex?: boolean;
}

interface Props {
    data: FormFieldsData;
    customFieldTypes?: CustomFieldType[];
    noRegexes?: boolean;
    allowSubtypes?: boolean;
}

export const FormFieldsEditor = (props: Props) => {
    const { data } = props;
    const { formatMessage } = useIntl();

    const copyText = useCopyText();

    const schema = useMemo(() => {
      if(!props.customFieldTypes?.length) {
        return FieldSchema;
      } else {
        return mergeSchema(
          FieldSchema,
          {
            fieldtype: createSelectSchema(
              [...FieldTypes.map(ft => ({ value: ft, label: ft })), ...props.customFieldTypes.map(ft => ({ value: ft.value, label: ft.value }))],
              { label_id: "forms.field.fieldtype" }
            ),
          }
        )
      }
    }, [props.customFieldTypes]);

    return (
        <Wrapper>
            {!data.isDefaultLocale && <Typography variant="caption"><FormattedMessage id="forms.page.non_default_locale_edit_fields_hint" /></Typography>}
            {data.items.map((field, idx) => {
                const canHaveValues = ["select_radio", "select_multi", "rank"].includes(field.fieldtype);
                const canHaveBlock = ["select_radio", "number", "boolean"].includes(field.fieldtype);
                const canHaveRegex = field.fieldtype === "text" || !!(props.customFieldTypes || []).find(ft => ft.value === field.fieldtype)?.canHaveRegex;
                return (
                    <FieldWrapper>
                        <FormGrid
                            columns="repeat(4,1fr)"
                            noMargin
                            onKeyDown={e => {
                                if(isHotkey("ctrl+enter", e)) {
                                    data.add();
                                }
                            }}
                            >
                            <FormControlsForFields
                                data={field}
                                schema={schema}
                                onChange={(o,c) => data.update(idx, c)}
                                errors={data.errors?.getInnerErrors(["fields", idx])}
                                fields={[
                                    ["title", {
                                      wrapperStyle: { gridColumn: "span 2" },
                                      controlProps: {
                                        autoFocus: data.insertedFieldId === field._id,
                                        endAdornment: (
                                          <>
                                            <Tooltip text_id="forms.page.change_field_id">
                                              <IconButton size="small" onClick={() => data.editFieldId.startEditing({ ...field, oldId: field._id })}>
                                                <Fingerprint />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip text_id="forms.field.copy_code_hint">
                                              <IconButton size="small" onClick={() => copyText(field._id)}>
                                                <FileCopyOutlined />
                                              </IconButton>
                                            </Tooltip>
                                          </>)
                                      }}],
                                    ["fieldtype", { labelIdPrefix: "forms.field.types", disabled: !data.isDefaultLocale }],
                                ]}
                                />

                            <Buttons>
                              <FormControlsForFields
                                data={field}
                                schema={FieldSchema}
                                onChange={(o,c) => data.update(idx, c)}
                                errors={data.errors?.getInnerErrors(["fields", idx])}
                                fields={[
                                    ["is_required", { disabled: !data.isDefaultLocale }],
                                    ["is_hidden", { disabled: !data.isDefaultLocale }],
                                ]}
                                />
                            </Buttons>

                              <FormControlsForFields
                                data={field}
                                schema={FieldSchema}
                                onChange={(o,c) => data.update(idx, c)}
                                errors={data.errors?.getInnerErrors(["fields", idx])}
                                fields={[
                                    ["hint", { autoRows: true, wrapperStyle: { gridColumn: canHaveValues ? "span 2" : ((canHaveRegex && !props.noRegexes) || props.allowSubtypes) ? "span 3" : "span 4"} }],
                                    canHaveValues && ["options_text", { autoRows: true, wrapperStyle: { gridColumn: "span 2" } }],
                                    canHaveBlock && ["block_submit_if"],
                                    canHaveBlock && ["hide_next_if"],
                                    canHaveRegex && !props.noRegexes && ["validation_regex", {
                                      controlProps: {
                                        endAdornment: <Buttons>
                                          <Tooltip text_id="forms.field.regex_help.phone">
                                            <IconButton size="small" onClick={() => data.update(idx, { validation_regex: "\\+?(\\d(\\s|-)*){5,15}" })}><PhoneOutlined /></IconButton>
                                          </Tooltip>
                                          <Tooltip text_id="forms.field.regex_help.reference">
                                            <IconButton href={formatMessage({ id: "forms.field.regex_help.reference_link"})} target="_blank" rel="noreferrer noopener" size="small"><InfoOutlined /></IconButton>
                                          </Tooltip>
                                        </Buttons>
                                      }
                                    }],
                                    props.allowSubtypes && ["subtype"],
                                    (field.block_submit_if || field.block_next_if) && ["block_submit_message", { autoRows: true, wrapperStyle: { gridColumn: "span 2"} }],
                                ]}
                                />
                        </FormGrid>
                        <div>
                            {data.items.length > 0 && <IconButton size="small" onClick={() => data.moveUp(idx)}><ArrowUpward /></IconButton>}
                            <IconButton size="small" onClick={() => data.remove(idx)}><DeleteOutlined /></IconButton>
                            {data.items.length > 0 && <IconButton size="small" onClick={() => data.moveDown(idx)}><ArrowDownward /></IconButton>}
                        </div>
                    </FieldWrapper>
                )})}

            <Button size="small" color="primary" onClick={() => data.add()} startIcon={<Add />}><FormattedMessage id="forms.page.add_field" /></Button>

            <SimpleDialog
              dialogTitle={<FormattedMessage id="forms.page.change_field_id" />}
              isOpen={data.editFieldId.isEditing}
              close={() => data.editFieldId.cancel()}
              save={() => data.editFieldId.save()}
              saveLabel={<FormattedMessage id="common.change" />}
              noFullscreen>
              <FormGrid columns="1fr" noMargin>
                <FormControlsForFields
                  data={data.editFieldId.item}
                  fields={[
                    ["title", { disabled: true }],
                    ["_id", { label: "ID" }],
                  ]}
                  onChange={(o,c) => data.editFieldId.update(c)}
                  schema={FieldSchema}
                  />
              </FormGrid>
            </SimpleDialog>
        </Wrapper>
    );
}
